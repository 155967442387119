@import "paper/blueprintjs";
@import "paper/flexbox";
@import "fonts/font";

@import "paper/spacing";

@import "~@blueprintjs/core/lib/scss/variables";

* {
  margin: 0;

  font-family: 'JetBrainsMono', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: $dark-gray1;
}