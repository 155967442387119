$spacer: 0.125rem;

$sm-viewport: 576px;
$md-viewport: 768px;
$lg-viewport: 992px;
$xl-viewport: 1200px;

@each $prop, $abbrev in (margin: m, padding: p) {
  @for $size from 0 through 50 {
    $spacing: $spacer * $size;

    .#{$abbrev}-#{$size} {
      #{$prop}: $spacing !important;
    }
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $spacing !important;
      #{$prop}-right: $spacing !important;
    }
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $spacing !important;
      #{$prop}-bottom: $spacing !important;
    }
    .#{$abbrev}t-#{$size} {
      #{$prop}-top: $spacing !important;
    }
    .#{$abbrev}r-#{$size} {
      #{$prop}-right: $spacing !important;
    }
    .#{$abbrev}b-#{$size} {
      #{$prop}-bottom: $spacing !important;
    }
    .#{$abbrev}l-#{$size} {
      #{$prop}-left: $spacing !important;
    }

    @media only screen and (max-width: ($sm-viewport - 1px)) {
      .#{$abbrev}-#{$size}-xs {
        #{$prop}: $spacing !important;
      }
      .#{$abbrev}x-#{$size}-xs {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}y-#{$size}-xs {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}t-#{$size}-xs {
        #{$prop}-top: $spacing !important;
      }
      .#{$abbrev}r-#{$size}-xs {
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}b-#{$size}-xs {
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}l-#{$size}-xs {
        #{$prop}-left: $spacing !important;
      }

      .child-#{$abbrev}-#{$size}-xs > * {
        #{$prop}: $spacing !important;
      }
      .child-#{$abbrev}x-#{$size}-xs > * {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .child-#{$abbrev}y-#{$size}-xs > * {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .child-#{$abbrev}t-#{$size}-xs > * {
        #{$prop}-top: $spacing !important;
      }
      .child-#{$abbrev}r-#{$size}-xs > * {
        #{$prop}-right: $spacing !important;
      }
      .child-#{$abbrev}b-#{$size}-xs > * {
        #{$prop}-bottom: $spacing !important;
      }
      .child-#{$abbrev}l-#{$size}-xs > * {
        #{$prop}-left: $spacing !important;
      }
    }

    @media only screen and (min-width: $sm-viewport) {
      .#{$abbrev}-#{$size}-sm {
        #{$prop}: $spacing !important;
      }
      .#{$abbrev}x-#{$size}-sm {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}y-#{$size}-sm {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}t-#{$size}-sm {
        #{$prop}-top: $spacing !important;
      }
      .#{$abbrev}r-#{$size}-sm {
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}b-#{$size}-sm {
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}l-#{$size}-sm {
        #{$prop}-left: $spacing !important;
      }

      .child-#{$abbrev}-#{$size}-sm > * {
        #{$prop}: $spacing !important;
      }
      .child-#{$abbrev}x-#{$size}-sm > * {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .child-#{$abbrev}y-#{$size}-sm > * {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .child-#{$abbrev}t-#{$size}-sm > * {
        #{$prop}-top: $spacing !important;
      }
      .child-#{$abbrev}r-#{$size}-sm > * {
        #{$prop}-right: $spacing !important;
      }
      .child-#{$abbrev}b-#{$size}-sm > * {
        #{$prop}-bottom: $spacing !important;
      }
      .child-#{$abbrev}l-#{$size}-sm > * {
        #{$prop}-left: $spacing !important;
      }
    }

    @media only screen and (min-width: $md-viewport) {
      .#{$abbrev}-#{$size}-md {
        #{$prop}: $spacing !important;
      }
      .#{$abbrev}x-#{$size}-md{
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}y-#{$size}-md {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}t-#{$size}-md {
        #{$prop}-top: $spacing !important;
      }
      .#{$abbrev}r-#{$size}-md {
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}b-#{$size}-md {
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}l-#{$size}-md {
        #{$prop}-left: $spacing !important;
      }
    }

    @media only screen and (min-width: $lg-viewport) {
      .#{$abbrev}-#{$size}-lg {
        #{$prop}: $spacing !important;
      }
      .#{$abbrev}x-#{$size}-lg {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}y-#{$size}-lg {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}t-#{$size}-lg {
        #{$prop}-top: $spacing !important;
      }
      .#{$abbrev}r-#{$size}-lg {
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}b-#{$size}-lg {
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}l-#{$size}-lg {
        #{$prop}-left: $spacing !important;
      }
    }

    @media only screen and (min-width: $xl-viewport) {
      .#{$abbrev}-#{$size}-xl {
        #{$prop}: $spacing !important;
      }
      .#{$abbrev}x-#{$size}-xl {
        #{$prop}-left: $spacing !important;
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}y-#{$size}-xl {
        #{$prop}-top: $spacing !important;
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}t-#{$size}-xl {
        #{$prop}-top: $spacing !important;
      }
      .#{$abbrev}r-#{$size}-xl {
        #{$prop}-right: $spacing !important;
      }
      .#{$abbrev}b-#{$size}-xl {
        #{$prop}-bottom: $spacing !important;
      }
      .#{$abbrev}l-#{$size}-xl {
        #{$prop}-left: $spacing !important;
      }
    }

    .child-#{$abbrev}-#{$size} > * {
      #{$prop}: $spacing !important;
    }
    .child-#{$abbrev}x-#{$size} > * {
      #{$prop}-left: $spacing !important;
      #{$prop}-right: $spacing !important;
    }
    .child-#{$abbrev}y-#{$size} > * {
      #{$prop}-top: $spacing !important;
      #{$prop}-bottom: $spacing !important;
    }
    .child-#{$abbrev}t-#{$size} > * {
      #{$prop}-top: $spacing !important;
    }
    .child-#{$abbrev}r-#{$size} > * {
      #{$prop}-right: $spacing !important;
    }
    .child-#{$abbrev}b-#{$size} > * {
      #{$prop}-bottom: $spacing !important;
    }
    .child-#{$abbrev}l-#{$size} > * {
      #{$prop}-left: $spacing !important;
    }
  }
}
